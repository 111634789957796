<!--
 * @Author: cyy
 * @version: 
 * @Date: 2021-03-06 00:26:18
 * @LastEditTime: 2022-05-20 10:29:57
 * @LastEditors: cyy
 * @Description: 手机网校
 * @FilePath: \teacher.dingdingkaike.com\src\views\NetSchollAdmin\NetSchollAdmins\SchollPreview.vue
-->
<template>
  <div id="SchollPreview">
    <div class="header">
      <div class="left">
        <el-button
          type="primary"
          size="medium"
          @click="newpage"
          :disabled="completelist.num_new >= completelist.num_all"
        >
          新建页面
        </el-button>
        <div class="number">
          页面数量：{{ completelist.num_new }}/{{ completelist.num_all }}
        </div>
      </div>
      <div class="right">
        <el-input
          v-on:keyup.enter.native="search"
          v-model="input"
          placeholder="搜索页面"
          class="mr10"
          size="medium"
        ></el-input>
        <el-button type="primary" size="medium" @click="search">搜索</el-button>
      </div>
    </div>
    <pagination2
      url="/HomeTemplate/templateList"
      ref="childDialogBox"
      class="childDialogBox"
      :option="post"
      @complete="complete"
      :hadPagination="false"
    >
      <template v-slot:default="{ tableData }">
        <el-table
          :header-cell-style="{
            background: 'rgba(245,245,245,1)',
            color: '#333333',
          }"
          :data="tableData"
        >
          <el-table-column label="页面名称">
            <template slot-scope="{ row }">
              <div class="name_contain">
                <div class="name">
                  {{ row.name }}
                </div>
                <div class="is_home" v-if="row.is_home == 1">首页</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="创建时间" prop="c_time">
            <template slot-scope="{ row }">
              {{ row.c_time | formatTimeStamp }}
            </template>
          </el-table-column>
          <el-table-column label="页面地址" prop="url"></el-table-column>
          <el-table-column label="操作" width="480px">
            <template slot-scope="{ row }">
              <div class="btn_contain">
                <!-- 装修  |  设为首页  |  分享  |  更多 -->
                <el-button type="text" @click="decorate(row)">装修</el-button>
                <span class="sp_diver">|</span>
                <el-button
                  type="text"
                  @click="settingindex(row)"
                  :disabled="row.is_home == 1"
                >
                  设为首页
                </el-button>
                <span class="sp_diver">|</span>
                <el-button type="text" @click="share(row)">分享</el-button>
                <span class="sp_diver">|</span>

                <el-button type="text" @click="edit(row)">页面设置</el-button>
                <span class="sp_diver">|</span>

                <el-button
                  type="text"
                  @click="copy(row)"
                  :disabled="completelist.num_new >= completelist.num_all"
                >
                  创建副本
                </el-button>
                <span class="sp_diver">|</span>

                <el-button
                  type="text"
                  @click="delpage(row)"
                  :disabled="row.is_home == 1"
                >
                  删除
                </el-button>

                <!-- <el-dropdown>
                  <span class="sp_name el-dropdown-link">更多</span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item>
                      <p @click="edit(row)">
                        页面设置
                      </p>
                    </el-dropdown-item>
                    <el-dropdown-item>
                      <div
                        style="color: #a1a1a1; cursor: no-drop"
                        v-if="completelist.num_new >= completelist.num_all"
                      >
                        创建副本
                      </div>
                      <p @click="copy(row)" v-else>创建副本</p>
                    </el-dropdown-item>
                    <el-dropdown-item>
                      <div
                        style="color: #a1a1a1; cursor: no-drop"
                        v-if="row.is_home == 1"
                      >
                        删除
                      </div>
                      <div v-else @click="delpage(row)">删除</div>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown> -->
              </div>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </pagination2>
    <!-- 新建页面 -->
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="422px"
      :close-on-click-modal="false"
      @closed="dialogVisibleclose"
    >
      <el-input
        type="text"
        placeholder="请输入页面名称"
        v-model="text"
        maxlength="16"
        show-word-limit
      ></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addTemplate">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 分享 -->
    <el-dialog
      title="分享"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible2"
      width="800px"
      @opened="create_ewm"
    >
      <div class="dialogVisible2_contain">
        <div class="left">
          <div id="qcode" ref="qrcode"></div>
          <el-button type="text" @click="downEwm">下载二维码</el-button>
        </div>
        <div class="right">
          <div class="url">{{ sharelist.url }}</div>
          <el-button
            type="text"
            :data-clipboard-text="sharelist.url"
            class="address-btn"
          >
            复制链接
          </el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import QRCode from '@/assets/js/qrcode'
import Clipboard from 'clipboard'
export default {
  data() {
    return {
      post: {
        name: '',
      },
      title: '新建页面',
      text: '',
      input: '',
      dialogVisible: false,
      dialogVisible2: false,
      edit_id: '',
      sharelist: {},
      completelist: {},
    }
  },

  components: {},

  mounted() {
    this.clipboard()
  },

  methods: {
    // 下载二维码
    downEwm() {
      const imgUrl = this.qrcode.getBase64Code()
      if (window.navigator.msSaveOrOpenBlob) {
        var bstr = atob(imgUrl.split(',')[1])
        var n = bstr.length
        var u8arr = new Uint8Array(n)
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n)
        }
        var blob = new Blob([u8arr])
        window.navigator.msSaveOrOpenBlob(blob, 'chart-download' + '.' + 'png')
      } else {
        // 这里就按照chrome等新版浏览器来处理
        const a = document.createElement('a')
        a.href = imgUrl
        a.setAttribute('download', 'chart-download')
        a.click()
      }
    },

    newpage() {
      this.title = '新建页面'
      this.dialogVisible = true
    },

    // 获取列表的数据
    complete(val) {
      this.completelist = val
    },

    // 关闭弹窗后，清楚文本内容
    dialogVisibleclose() {
      this.text = ''
      this.edit_id = ''
    },

    // 新建页面 页面设置
    async addTemplate() {
      if (!this.text.trim()) {
        this.$root.prompt('请输入页面名称！')
        return
      }
      if (this.edit_id) {
        await this.$http({
          url: '/HomeTemplate/setName',
          data: { name: this.text, t_home_id: this.edit_id },
          successMsg: true,
        })
        this.edit_id = ''
      } else {
        await this.$http({
          url: '/HomeTemplate/addTemplate',
          data: { name: this.text },
          successMsg: true,
        })
      }

      this.dialogVisible = false
      this.$refs.childDialogBox.reset()
    },

    // 创建副本
    async copy(row) {
      await this.$http({
        url: '/HomeTemplate/copy',
        data: { t_home_id: row.t_home_id },
        successMsg: '创建副本成功！',
      })
      this.$refs.childDialogBox.reset()
    },

    // 装修
    decorate(val) {
      let url = this.$router.resolve({
        path: '/onlineSchoolDecoration',
        query: { id: val.t_home_id, name: val.name },
      })
      this.$store.dispatch('open', url.href)
    },

    // 搜索
    search() {
      this.post.name = this.input
    },

    // 设置为首页
    settingindex(row) {
      this.$confirm(
        `设为首页后，访问链接 ${this.$store.state.userInfo.pcHomeDomain} 时或点击部分网校页面的【首页】按钮时，会默认进入当前页面！`,
        '设为首页',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }
      )
        .then(async () => {
          await this.$http({
            url: '/HomeTemplate/setIndex',
            data: { t_home_id: row.t_home_id },
            successMsg: '首页设置成功！',
          })
          this.$refs.childDialogBox.reset()
        })
        .catch(() => {})
    },

    // 删除
    async delpage(row) {
      await this.$confirm(
        '删除后已装修的内容将被清理，无法找回，是否确定删除？',
        '删除微页面',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }
      )
        .then(async () => {
          await this.$http({
            url: '/HomeTemplate/delete',
            data: { t_home_id: row.t_home_id },
            successMsg: true,
          })
          this.$refs.childDialogBox.reset()
        })
        .catch(() => {})
    },

    // 复制
    clipboard() {
      const self = this
      const clipboard = new Clipboard('.address-btn')

      clipboard.on('success', () => {
        self.$root.prompt({
          type: 'success',
          msg: '复制成功',
        })
      })
      clipboard.on('error', () => {
        self.$root.prompt('复制失败')
      })
    },

    // 分享
    share(row) {
      this.sharelist = row
      this.dialogVisible2 = true
    },

    // 生成二维码
    create_ewm() {
      const size = 155
      //生成前先清空原有的，不然会有多张二维码的情况
      this.$refs.qrcode.innerHTML = ''
      const node = this.$refs.qrcode
      if (node) {
        this.qrcode = new QRCode(node, {
          text: this.sharelist.url, //地址
          width: size,
          height: size,
          colorDark: '#000000',
          colorLight: '#ffffff',
          correctLevel: QRCode.CorrectLevel.H,
        })
      }
      this.imgUrl = this.qrcode.getBase64Code()
    },

    // 编辑
    edit(row) {
      this.dialogVisible = true
      this.title = '页面设置'
      this.edit_id = row.t_home_id
      this.text = row.name
    },
  },
}
</script>

<style lang="less" scoped>
#SchollPreview {
  padding: 20px;
  .header {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    .left {
      display: flex;
      align-items: center;
      .number {
        font-size: 14px;
        color: #333333;
        margin-left: 20px;
      }
    }
    .right {
      display: flex;
      align-content: center;
    }
  }
  .childDialogBox {
    .btn_contain {
      .sp_name {
        color: #0aa29b;
        padding: 0 5px;
        line-height: 1.5;
        cursor: pointer;
      }
      .sp_diver {
        margin: 0 10px;
        color: #1b9d97;
      }
    }
    .name_contain {
      display: flex;
      .is_home {
        margin-left: 10px;
        width: 32px;
        height: 18px;
        background: #ffffff;
        border-radius: 2px;
        border: 1px solid #0aa29b;
        font-size: 12px;
        color: #0aa29b;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 12px;
      }
    }
  }
  .dialogVisible2_contain {
    display: flex;
    justify-content: center;
    .left {
      margin-right: 40px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .code {
        width: 155px;
        height: 155px;
        margin-bottom: 10px;
      }
    }
    .right {
      display: flex;
      flex-direction: column;
      align-items: center;
      .url {
        box-sizing: border-box;
        width: 370px;
        height: 155px;
        background: #f5f5f5;
        border-radius: 6px;
        padding: 18px 20px;
        margin-bottom: 10px;
      }
    }
  }
}
</style>

<template>
  <el-card class="box-card">
    <div class="text item">
      <!-- <el-tabs tab-position="top">
        <el-tab-pane label="网校预览">
        </el-tab-pane>
        <el-tab-pane label="网校模块">
          <ScrollModel />
        </el-tab-pane>
        <el-tab-pane label="网校导航">
          <SchollNavigation />
        </el-tab-pane>
      </el-tabs> -->

      <ScrollPreview />
    </div>
  </el-card>
</template>

<script>
import ScrollPreview from './NetSchollAdmins/SchollPreview' //网校预览
// import ScrollModel from './NetSchollAdmins/SchollModel' //网校模块
// import SchollNavigation from './NetSchollAdmins/SchollNavigation' //网校导航
import background from '@/mixin/background'

export default {
  data() {
    return {}
  },

  mixins: [background],

  components: {
    ScrollPreview,
    // ScrollModel,
    // SchollNavigation,
  },
}
</script>

<style lang="less" scoped>
.text {
  font-size: 14px;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}

.box-card {
  margin: 20px;
}
/deep/ .el-card__body {
  padding: 0px;
}
/deep/ .el-tabs__nav.is-top {
  transform: translateX(30px) !important;
  height: 46px;
  line-height: 46px;
}
/deep/ .el-card__body {
  padding: 0px;
}

/deep/ .el-tabs__nav-wrap::after {
  height: 1px;
}
/deep/ .el-tabs__header.is-top {
  margin: 0px;
}
/deep/ .el-tabs__item.is-active {
  color: #0aa29b;
}
/deep/ .el-tabs__item:hover {
  color: #0aa29b;
}

/deep/ .el-tabs__active-bar {
  background-color: #0aa29b;
  height: 1px;
}
</style>

import { render, staticRenderFns } from "./PhoneScholl.vue?vue&type=template&id=64394569&scoped=true&"
import script from "./PhoneScholl.vue?vue&type=script&lang=js&"
export * from "./PhoneScholl.vue?vue&type=script&lang=js&"
import style0 from "./PhoneScholl.vue?vue&type=style&index=0&id=64394569&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64394569",
  null
  
)

export default component.exports